export default {
  ROOT_PATH: '/',
  LOGIN_PATH: '/login',
  REGISTER_PATH: '/register',
  TYPE_SNACK_BAR: {
    ERROR: "error",
    SUCCESS: "success",
    WARNING: "warning"
  },
  STATUS_USER: {
    OFFLINE: 0,
    ONLINE: 1,
    INGAME: 2,
    BUSY: 3
  },
  PARTY_MODE: {
    RANKING: "ranking",
    NORMAL: "normal"
  },
  PARTY_STATE: {
    opening: "opening",
    waiting: "waiting",
    found: "found",
    ready: "ready",
    gamingBan: "gaming_ban",
    gaming: "gaming",
    preFinish: "pre_finish",
    finish: "finish",
    reject: "reject",
    timeout: "timeout"
  },
  MAP_STATE: {
    open: 0,
    ban: 1,
    pick: 2
  },
  PLAYER_STATE: {
    init: 0,
    ready: 1,
    connectedGame: 2,
    disconnectGame: 3,
  },
  LOBBY_STATE: {
    init: 0,
    creatingServer: 1,
    running: 2,
    pause: 3,
  },
  TYPE_BOX: {
    allItem: 77,
    profile: 78,
    tools: 79,
    spells: 80,
    other: 81
  },
  CATEGORY: {
    box: 32,
    artifacts: 31,
    shopMall: 51,
    supplement: 13
  },
  CURRENCY_TYPE: {
    coin: 0,
    point: 1,
    gpoint: 2
  },
  SHOP_ITEMS: {
    fashion: 1,
    props: 2,
    supplement: 3,
  },
  EXCHANGE_GIFFS: {
    inGame: 1,
    artifacts: 2
  },
  SUB_CATEGORY: {
    avatar: 1110,
    avatarGB: 1160,
    frame: 1120,
    frameGB: 1170,
    effect: 1140,
    avatarExclusive: 1361,
    frameExclusive: 1371,
    effectExclusive: 1373,
    nameChangeCard: 1310,
    resetRankingCS2: 1322,
    boostCS2: 1323,
    CS2RankingTicket: 1324,
    box: 3210,
    voucher: 3220,
    shopMallGb: 3230
  },
  COMPONET_PROFILE: {
    statistic: 0,
    inventory: 1,
    inforPlayer: 2,
    historyTransaction: 3
  },
  USER_ITEM_STATUS: {
    owned: 3,
    using: 5
  },
  MISSION_STATE: {
    Unfulfilled: 0,
    Progress: 1,
    Complete: 2,
    ReceivedAll: 3,
    ExpireTime: 4,
    CannotReceive: 5
  },
  EVENT_TIME_STATE: {
    HappeningTime: 1,
    OverTime: 2,
  },
  REWARD_STATUS: {
    Missing: 0,
    Pending: 1,
    Fail: 2,
    Success: 5
  },
  GAME_STATE: {
    UnActive: 0,
    Active: 1
  },
  GAME_TYPE: {
    normal: 0,
    tournamentsCommunity: 3
  },
  TRANSACTION_TYPE: {
    1: "Phần thưởng",
    2: "Phần quà từ nhiệm vụ",
    3: "Thanh toán",
    5: "Nạp tiền",
    6: "Phần quà từ admin",
    8: "Quà tặng",
    9: "Phần quà từ nhiệm vụ",
    10: "Số thần tài",
    11: "Số thần tài",
  },
  NOTIFICATION_TYPE: {
    TypeNotificationGBPopup                : 27,
    TypeNotificationGBHome                 : 28,
    TypeNotificationGBMission              : 29,
    TypeNotificationGBShop                 : 30,
    TypeNotificationGBExchangeGifts        : 31,
    TypeNotificationGBCommunicationForum   : 32,
    TypeNotificationGBCommunicationDiscord : 33,
    TypeNotificationGBCommunicationFanPage : 34,
    TypeNotificationGBCommunicationYoutube : 35,
    TypeNotificationGBCommunicationGroupFB : 36,
    TypeNotificationGBCommunicationTiktok  : 37,
    TypeNotificationGBStatistics           : 38,
    TypeNotificationGBItems                : 39,
    TypeNotificationGBPersonal             : 40,
    TypeNotificationGBEvent                : 41,
  },
  NOTIFICATION_ACTION: {
    CreatedNotify                          : 'created_notify',
    NewNotify                              : 'new_notify'
  },
}