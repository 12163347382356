<template>
  <div class="cl-mem noselect">
    <v-expand-transition v-for="(item, index) in listMember" :key="index">
      <div class="cl-mem-info" v-show="showCardMember">
        <div
          :class="index === currentUserIndex ? 'cl-current-user' : 'cl-member'"
        >
          <img
            v-if="index === currentUserIndex"
            src="@/assets/images/battle-room/mem_leader.svg"
            alt=""
          />
          <img
            v-else
            src="@/assets/images/battle-room/mem_background.svg"
            alt=""
          />
        </div>
        <div v-if="item?.name" class="cl-info">
          <div class="cl-leader-icon">
            <img
              v-show="item?.is_party_leader"
              class="w-7"
              src="@/assets/images/battle-lobby/icon_leader.svg"
              alt=""
            />
            <v-btn
              v-show="isBtnKickPlayer(item)"
              class="mt-n4"
              color="#7B72C6"
              icon
              variant="plain"
              @click.stop="onKickMember(item)"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </div>
          <div class="cl-avatar relative" @click="onGotoProfile(item)">
            <Avatar
              :data="item"
              :sizeAvatar="91"
              :sizeFrame="98"
              :tooltip="false"
            />
          </div>
          <div class="w-40 text-center text-white cl-font-18-bold maxline1 mt-1">{{ item?.name }}</div>
          <div
            v-show="item?.steam_display_name"
            class="cl-name-steam cl-font-14-normal maxline1 cursor-pointer"
            @click="showProfileSteam(item?.steam_id)"
          >
            <v-icon size="16">mdi-steam</v-icon>
            <span class="w-29 maxline1">
              &nbsp;{{ item?.steam_display_name }}
            </span>
          </div>
          <BaseBanTime
            :item="item"
            :timeCountDown="item?.penalty_period_time"
            @closeBanTime="() => (item.penalty_period_time = 0)"
          />
          <div
            v-if="item?.rank"
            class="cl-font-16-bold"
            :class="
              index === currentUserIndex ? 'cl-elo-rank' : 'cl-elo-rank-mem'
            "
          >
            <v-img
              class="w-36"
              :src="item?.rank_background"
              alt=""
              cover
            ></v-img>
            <div
              class="w-16 flex justify-center absolute cl-font-24-bold italic mt-2 mr-10 maxline1"
              :style="{ color: item?.elo_color }"
            >
              {{ item?.elo }}
            </div>
          </div>
        </div>
        <div v-else class="cl-info">
          <v-btn class="cl-avatar-member" @click="onOpenDrawerFriend"></v-btn>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import constants from "@/constants";

// component
import Avatar from "@/components/core/Avatar.vue";

// storage
import { getUserInfo } from "@/helpers/storage";

// mixins
import ranking from "@/mixins/ranking";
import steamConnect from "@/mixins/steamConnect";

// service
import { BattleService } from "../../services/battleServices";

// router
import routesName from "@/routers/routesName";

export default {
  mixins: [ranking, steamConnect],
  components: {
    Avatar,
  },

  props: {
    infoParty: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const store = useStore(key);
    const currentUserIndex = 2;
    const showCardMember = ref(false);
    const listMember = ref([{}, {}, {}, {}, {}]);
    const userInfo = JSON.parse(getUserInfo());

    const drawerFriend = computed(() => store.state.drawerFriend);
    const drawerChat = computed(() => store.state.drawerChat);

    const onOpenDrawerFriend = () => {
      if (drawerChat.value) {
        onCloseDrawerChat();
      }
      store.dispatch("setDrawerFriend", !drawerFriend.value);
    };

    watch(
      () => props.infoParty?.members,
      () => {
        updateListMember(props.infoParty?.members);
      }
    );

    function onCloseDrawerChat() {
      store.dispatch("setDrawerChat", false);
    }

    function isBtnKickPlayer(item) {
      return Boolean(
        props.infoParty.party_leader === userInfo?.user_id &&
          userInfo?.user_id !== item.user_uuid
      );
    }

    function updateListMember(data) {
      listMember.value = [{}, {}, {}, {}, {}];
      data.forEach((it) => {
        updateMember(it);
      });
    }

    function updateLeader(item) {
      const indexLeader = listMember.value.findIndex(
        (it) => it.user_uuid === item.user_uuid
      );
      if (indexLeader >= 0) {
        listMember.value[indexLeader] = item;
      }
    }

    function updateSteam(data) {
      const index = listMember.value.findIndex(
        (it) => it.user_uuid === data.user_id
      );
      if (index >= 0) {
        listMember.value[index].steam_display_name = data.steam_name;
      }
    }

    function updateInfo(data, field) {
      const index = listMember.value.findIndex(
        (it) => it.user_uuid === data.user_uuid
      );
      if (index >= 0) {
        listMember.value[index][field] = data[field];
      }
    }

    function updateMember(item) {
      if (item.user_uuid === userInfo?.user_id) {
        listMember.value[currentUserIndex] = item;
        return;
      }
      if (!Object.keys(listMember.value[currentUserIndex - 1]).length) {
        listMember.value[currentUserIndex - 1] = item;
        return;
      }
      if (!Object.keys(listMember.value[currentUserIndex + 1]).length) {
        listMember.value[currentUserIndex + 1] = item;
        return;
      }
      if (!Object.keys(listMember.value[currentUserIndex - 2]).length) {
        listMember.value[currentUserIndex - 2] = item;
        return;
      }
      if (!Object.keys(listMember.value[currentUserIndex + 2]).length) {
        listMember.value[currentUserIndex + 2] = item;
        return;
      }
    }

    onMounted(() => {
      setTimeout(() => {
        showCardMember.value = true;
      }, 300);
    });

    return {
      listMember,
      showCardMember,
      currentUserIndex,
      updateMember,
      updateLeader,
      updateSteam,
      updateListMember,
      updateInfo,
      isBtnKickPlayer,
      onOpenDrawerFriend,
    };
  },

  methods: {
    async onKickMember(item) {
      try {
        await BattleService.kickMember({
          user_uuid: item.user_uuid,
        });
      } catch (error) {
        this.$store.dispatch("setSnackBar", {
          show: true,
          message: this.$t(`error_code[${error}]`),
          type: constants.TYPE_SNACK_BAR.ERROR,
        });
      }
    },

    onGotoProfile(item) {
      this.$router.push({
        name: routesName.PROFILE,
        params: {
          userId: item.user_uuid,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-mem {
  flex: 1;
  display: flex;
  gap: 32px;
  margin-top: -60px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  .cl-member {
    width: 178px;
    height: 433px;
    object-fit: cover;
  }
  .cl-current-user {
    width: max-content;
    height: 514px;
    object-fit: cover;
  }
  .cl-mem-info {
    position: relative;
    .cl-leader-icon {
      position: absolute;
      top: 25px;
    }
    .cl-info {
      width: 100%;
      height: 100%;
      top: 0;
      align-items: center;
      align-content: center;
      display: flex;
      flex-direction: column;
      position: absolute;
      .cl-empty,
      .cl-avatar,
      .cl-avatar-member {
        width: 82px;
        height: 82px;
        margin-top: 66px;
        border-radius: 50%;
        cursor: pointer;
        margin-top: 56px;
        box-shadow: none;
        background: transparent;
        border: 2px solid #00c2ff33;
      }
      .cl-avatar {
        width: 91px;
        height: 91px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }
      .cl-name-steam {
        width: 80%;
        justify-content: center;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-top: -5px;
        position: relative;
        color: rgba(255, 255, 255, 0.5);
      }
      .cl-elo-rank-mem,
      .cl-elo-rank {
        width: 80%;
        align-items: center;
        flex-direction: column;
        display: flex;
        flex-wrap: nowrap;
        margin-top: 165px;
      }
      .cl-elo-rank-mem {
        margin-top: 124px;
      }
    }
  }
}
@keyframes light-flow {
  0% {
    transform: translateX(-100%) translateY(-100%);
  }
  100% {
    transform: translateX(100%) translateY(100%);
  }
}
</style>
