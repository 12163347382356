<template>
  <div class="flex my-4 mx-5">
    <div
      class="w-max h-9 flex justify-center items-center border-2 border-customPink rounded-md px-2"
    >
      <v-select
        v-model="modelSelected"
        :items="lstFilter"
        class="min-w-40 h-9 mt-n1"
        variant="plain"
        density="compact"
        hide-no-data
        menu-icon=""
        return-object
        @update:modelValue="onSearchRequest"
      >
        <template v-slot:append-inner>
          <v-icon class="mr-n2" color="#7B72C6"> mdi-chevron-down </v-icon>
        </template>
        <template v-slot:item="{ props, item }">
          <div>
            <v-list-item class="hover:bg-customPurple" v-bind="props">
              <template v-slot:title>
                <div class="cl-font-14-bold text-white">
                  {{ item.raw.title }}
                </div>
              </template>
            </v-list-item>
          </div>
        </template>
        <template v-slot:selection="{ item }">
          <span class="text-customPink cl-font-14-bold maxline1">
            {{ item.props.title }}
          </span>
        </template>
      </v-select>
    </div>
    <div class="flex items-center w-80 h-9 ml-5 pr-2">
      <v-autocomplete
        density="compact"
        variant="outlined"
        class="select-user border-0 border-customPink rounded-md text-white"
        menu-icon=""
        placeholder="Tìm người chơi"
        clearable
        :no-filter="true"
        :items="dataUser"
        :hide-details="true"
        return-object
        @update:search="(val) => searchUserFriends(val)"
        @update:modelValue="(val) => setModelKeySearch(val?.UserId)"
      >
        <template v-slot:append-inner>
          <v-icon class="mr-n2" color="#7B72C6"> mdi-chevron-down </v-icon>
        </template>
        <template v-slot:no-data>
          <div class="cl-font-14-bold text-white flex justify-center">
            Danh sách rỗng
          </div>
        </template>
        <template v-slot:item="{ props, item }">
          <div ref="scrollContainer">
            <v-list-item
              class="cl-font-14-bold text-white border-b-2"
              v-bind="props"
              :title="item.raw.DisplayName"
            ></v-list-item>
          </div>
        </template>
        <template v-slot:selection="{ item }">
          <div class="cl-font-14-bold text-white">
            {{ item.raw.DisplayName }}
          </div>
        </template>
      </v-autocomplete>
    </div>
  </div>
  <div>
    <v-data-table
      id="id-virtual-rank"
      :headers="headers"
      :items="dataTable"
      :loading="loading"
      :height="getHeightTable()"
      no-filter
      disable-sort
      fixed-header
      hide-default-footer
      density="comfortable"
      item-value="top_rank"
      items-per-page="-1"
      loading-text="Đang tải dữ liệu"
      no-data-text="Không có dữ liệu"
      v-scroll.self="onScroll"
      @click:row="handleClickRow"
    >
      <template v-slot:[`header.rank_background`]="{ column }">
        <div class="flex justify-center">
          {{ column.title }}
          <img
            class="ml-3"
            src="@/assets/images/icons/outline_question.svg"
            alt=""
            srcset=""
          />
          <v-tooltip width="1000" activator="parent" location="bottom" attach>
            <img src="@/assets/images/ranks/rank_detail.svg" alt="" srcset="" />
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.top_rank`]="{ item }">
        <div class="relative flex items-center justify-center">
          <img
            v-if="item.top_rank < 6"
            class="absolute"
            :src="getBgImageTop(item.top_rank)"
            alt=""
            srcset=""
            style="z-index: -1"
          />
          <div
            :class="
              item.top_rank > 5 ? 'cl-font-12-bold py-0.5' : 'cl-font-16-bold'
            "
          >
            {{ item.top_rank }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.display_name`]="{ item }">
        <div class="flex items-center">
          <img
            class="w-6 h-6 mr-2 rounded-full"
            :src="item.avatar_url"
            alt=""
            srcset=""
          />
          <div class="cl-font-14-bold max-w-64 maxline1">
            {{ item.display_name }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.rank_background`]="{ item }">
        <div class="relative flex items-center justify-center w-full">
          <img
            class="w-26 h-9"
            :src="item.rank_background"
            alt=""
            srcset=""
            style="z-index: -1"
          />
          <div
            class="absolute cl-font-18-bold italic mt-2 ml-n9 w-16 flex justify-center maxline1"
            :style="{ color: item.elo_color }"
          >
            {{ item.elo }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.cs_rating`]="{ item }">
        <div class="relative flex items-center w-full">
          <img
            class="w-16 h-6"
            :src="item.cs_rating_url"
            alt=""
            srcset=""
            style="z-index: -1"
          />
          <div
            class="absolute cl-font-14-bold italic w-14 ml-1 flex justify-center maxline1"
            :style="{ color: item.cs_rating_color }"
          >
            {{ item.cs_rating }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.rank_url`]="{ item }">
        <img class="w-12 h-5" :src="item.rank_url" alt="" srcset="" />
      </template>
      <template v-slot:[`item.favourite_map_url`]="{ item }">
        <div v-if="item.favourite_map_url" class="flex items-center">
          <img
            class="w-12 h-8 rounded-sm"
            :src="item.favourite_map_url"
            alt=""
            srcset=""
          />
          <div class="ml-1 cl-font-14-normal">
            {{ item.favourite_map_name }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.favourite_weapon_url`]="{ item }">
        <div v-if="item.favourite_weapon_url" class="flex items-center">
          <img
            class="w-10 h-8"
            :src="item.favourite_weapon_url"
            alt=""
            srcset=""
          />
          <div class="ml-1 cl-font-14-normal">
            {{ item.favourite_weapon_name }}
          </div>
        </div>
      </template>
    </v-data-table>
    <div
      v-show="loadingBottom"
      class="absolute z-10 bottom-0 flex justify-center items-center w-full"
    >
      <v-progress-linear
        height="2"
        color="#fff"
        indeterminate
      ></v-progress-linear>
    </div>
  </div>
</template>

<script>
// rounter
import routesName from "@/routers/routesName";

// service
import { AuthService } from "@/services/authService";

// mixins
import ranking from "@/mixins/ranking";

export default {
  mixins: [ranking],

  props: {
    headers: {
      typeof: Array,
      default: () => [],
    },
    isLoadMore: {
      typeof: Boolean,
      default: true,
    },
    lstFilter: {
      typeof: Array,
      default: () => [],
    },
    dataTable: {
      typeof: Array,
      default: () => [],
    },
    loading: {
      typeof: Boolean,
      default: false,
    },
    loadingBottom: {
      typeof: Boolean,
      default: false,
    },
  },

  data() {
    return {
      timeOut: null,
      timeOutOnScroll: null,
      modelKeySearch: null,
      modelSelected: null,
      dataUser: [],
    };
  },

  methods: {
    getHeightTable() {
      return process.env.VUE_APP_BUILD == "DESKTOP-APP"
        ? "calc(100vh - 205px)"
        : "calc(100vh - 191px)";
    },

    searchUserFriends(val) {
      try {
        this.dataUser = [];
        if (!val || val.length < 1) return;
        if (this.timeOut) {
          clearTimeout(this.timeOut);
        }
        this.timeOut = setTimeout(async () => {
          const params = {
            display_name: val,
            pageSize: 50,
          };
          const response = await AuthService.searchUserAll(params);
          this.dataUser = response.Data;
        }, 500);
      } catch (error) {
        console.error(error);
      }
    },

    async handleClickRow(item, row) {
      try {
        this.$router.push({
          name: routesName.PROFILE,
          params: {
            userId: row.item.user_id,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },

    setModelSelected(val) {
      this.modelSelected = val;
    },

    getModelSelected() {
      return this.modelSelected;
    },

    setModelKeySearch(val) {
      this.modelKeySearch = val;
      this.onSearchRequest();
    },

    getModelKeySearch() {
      return this.modelKeySearch ?? null;
    },

    onSearchRequest() {
      document.getElementById("id-virtual-rank").scrollTop = 0;
      setTimeout(() => {
        this.$emit("changeSearchRequest");
      }, 500);
    },

    onScroll() {
      this.timeOutOnScroll && clearTimeout(this.timeOutOnScroll);
      this.timeOutOnScroll = setTimeout(() => {
        var item = document.getElementById("id-virtual-rank");
        if (
          this.isLoadMore &&
          Math.abs(item.scrollHeight - item.clientHeight - item.scrollTop) < 450
        ) {
          this.$emit("changeSearchRequest", true);
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-overlay__content) {
  margin-right: 20px;
  background: transparent !important;
}
::v-deep(.v-text-field input) {
  color: rgba(255, 255, 255, 1) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

#id-virtual-rank {
  overflow-y: auto !important;
}
.v-table {
  color: #fff !important;
  background: transparent !important;
  ::v-deep(hr) {
    display: none;
  }
}
::v-deep(.v-table__wrapper) {
  overflow: initial !important;
}
::v-deep(.v-data-table__td) {
  border: 0px !important;
}
::v-deep(.v-data-table__tr:nth-child(even)) {
  background: rgba(255, 255, 255, 0.05);
}
::v-deep(.v-data-table__tr:nth-child(odd)) {
  background: rgba(0, 0, 0, 0.3);
}
::v-deep(
    .v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th
  ) {
  background: rgba(0, 0, 0, 1);
}
::v-deep(.v-data-table__th) {
  background: rgba(0, 0, 0, 1);
}
::v-deep(.v-data-table-header__content) {
  font-size: 14px;
  font-weight: 700;
}
::v-deep(.v-data-table__th:last-child) {
  width: 150px !important;
  color: rgba(230, 186, 32, 1);
}
::v-deep(.v-data-table__td) {
  font-size: 14px;
  font-weight: 400;
}
::v-deep(.v-data-table__tr:hover) {
  background: rgba(123, 114, 198, 0.2);
}

.select-user {
  ::v-deep(.v-input__control) {
    height: 36px !important;
  }
  ::v-deep(.v-field__outline) {
    --v-field-border-width: 2px !important;
    --v-field-border-opacity: 1 !important;
  }
}
</style>