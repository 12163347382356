const entryPoint = 'gamification/api/v2.0/'
const entryUsers = 'users/api/v2.0'

export default {
  GET_TYPE_BOX: `${entryPoint}user/vouchers`,
  USING_AVATAR: `${entryPoint}user/avatar`,
  USING_FRAME: `${entryPoint}user/frame`,
  USING_EFFECT: `${entryPoint}user/effect`,
  USING_VOUCHER: `${entryPoint}user/voucher`,
  UPDATE_PROFILE: `${entryUsers}/profile`,
  UPDATE_EMAIL: `${entryUsers}/account/email/update`,
  GET_PERSONAL_ITEMS: `${entryPoint}user/items-owned`,
  GET_HISTORY_TRANSACTION: `${entryPoint}audit`,
}