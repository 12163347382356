import constants from '@/constants';
import routesName from './routesName';
import routesPath from './routesPath';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

// component
import Room from '@/modules/battleCS2/view/Room.vue';
import Lobby from '@/modules/battleCS2/view/Lobby.vue';
import FindTeam from '@/modules/battleCS2/view/FindTeam.vue';
import Ranking from '@/modules/battleCS2/view/Ranking.vue';
import RankingNormal from '@/modules/battleCS2/view/RankingNormal.vue';
import PlayNormal from '@/modules/battleCS2/view/PlayNormal.vue';

export const routersBattle = [
  {
    name: routesName.ROOM_BATTLE,
    path: routesPath.ROOM_BATTLE_PATH,
    component: Room
  },
  {
    name: routesName.LOBBY_BATTLE,
    path: routesPath.LOBBY_BATTLE_PATH,
    component: Lobby
  },
  {
    name: routesName.FIND_TEAM,
    path: routesPath.FIND_TEAM_PATH,
    component: FindTeam
  },
  {
    name: routesName.RANKING,
    path: routesPath.RANKING,
    component: Ranking
  },
  {
    name: routesName.RANKING_NORMAL,
    path: routesPath.RANKING_NORMAL_PATH,
    component: RankingNormal
  },
  {
    name: routesName.NORMAL,
    path: routesPath.NORMAL,
    component: PlayNormal,
    // TODO: WEB_PROD
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      // if (process.env.VUE_APP_BUILD === "DESKTOP-APP") {
      //   next();
      // } else {
      //   next(constants.ROOT_PATH);
      // }
      next();
    }
  },
  {
    name: routesName.HISTORY_BATTLE,
    path: routesPath.HISTORY_BATTLE_PATH,
    component: Lobby
  },
]