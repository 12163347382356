<template>
  <div id="id-statistic" class="cl-statistic" v-scroll.self="onScroll">
    <div class="flex justify-center gap-5">
      <div
        v-if="!loadingInfo"
        class="cl-rank-season flex flex-col items-center justify-center bg-customPurple4"
      >
        <div
          v-if="userInfo?.rank > 0"
          class="relative flex items-center justify-center w-max"
        >
          <img
            class="w-46 h-15"
            :src="userInfo?.rank_background"
            alt=""
            srcset=""
            style="z-index: -1"
          />
          <div
            class="absolute w-24 flex justify-center cl-font-32-bold italic mt-2 mr-14 maxline1"
            :style="{ color: userInfo?.elo_color }"
          >
            {{ userInfo?.elo }}
          </div>
        </div>
        <div class="cl-font-16-bold text-customPurple mt-2">
          {{ userInfo?.season_name }}
        </div>
      </div>
      <div v-else class="cl-rank-season bg-customPurple4">
        <v-skeleton-loader color="transparent" type="card"></v-skeleton-loader>
      </div>
      <div v-for="(item, index) in statisticItems" :key="index">
        <div
          v-if="!loadingInfo"
          class="cl-statistic-item flex flex-col items-center justify-center bg-customPurple4 pa-2"
        >
          <div class="cl-font-16-normal text-customPurple">{{ item.name }}</div>
          <div class="cl-font-32-bold text-white maxline1">
            {{ userInfo ? userInfo[item.key] ?? 0 : 0 }}
          </div>
        </div>
        <div v-else class="cl-statistic-item bg-customPurple4">
          <v-skeleton-loader
            color="transparent"
            type="card"
          ></v-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="flex justify-center gap-5 mt-5">
      <div v-if="!loadingInfo" class="cl-win-rate">
        <div class="w-36 flex items-center justify-center relative">
          <DoughnutChart
            :data="userInfo?.match_calculator ?? [0, 0]"
            :labels="userInfo?.match_label ?? []"
          />
          <div class="absolute flex flex-col items-center justify-center">
            <div class="cl-font-16-normal text-customPurple">Tỉ lệ thắng</div>
            <div class="cl-font-36-bold text-white mt-n2">
              {{ userInfo?.win_rate ?? 0 }}%
            </div>
          </div>
        </div>
        <div class="flex">
          <div
            v-for="(item, index) in userInfo?.match_label ?? []"
            :key="index"
            class="flex"
          >
            <div class="w-max text-center">
              <div class="cl-font-24-bold text-customPurple">
                {{ item }}
              </div>
              <div class="cl-font-24-bold text-white maxline1">
                {{
                  userInfo?.match_calculator
                    ? userInfo?.match_calculator[index] ?? 0
                    : 0
                }}
              </div>
            </div>
            <div
              class="mx-2 flex cl-font-24-bold text-customPurple"
              v-if="index < userInfo?.match_label.length - 1"
            >
              -
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="w-85 h-46 rounded-xxl overflow-hidden bg-customPurple4"
      >
        <v-skeleton-loader color="transparent" type="card"></v-skeleton-loader>
      </div>
      <div v-if="!loadingInfo" class="cl-radar-chart overflow-hidden">
        <div class="w-56">
          <RadarChart
            :labels="userInfo?.map_list ?? []"
            :data="userInfo?.win_rate_map ?? []"
          />
        </div>
        <div>
          <div v-for="(item, index) in userInfo?.map_list ?? []" :key="index">
            <div class="flex cl-font-14-bold text-white">
              <div
                :style="{
                  color:
                    index < colorRadarChart.length
                      ? colorRadarChart[index]
                      : colorRadarChart[0],
                }"
              >
                {{
                  userInfo?.win_rate_map
                    ? userInfo?.win_rate_map[index] ?? 0
                    : 0
                }}%
              </div>
              <div class="w-max ml-2 mb-1">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="w-85 h-46 rounded-xxl overflow-hidden bg-customPurple4"
      >
        <v-skeleton-loader color="transparent" type="card"></v-skeleton-loader>
      </div>
      <div class="cl-achievement">
        <span class="cl-font-16-bold text-customPurple">Coming Soon</span>
      </div>
    </div>
    <div class="mt-8">
      <Matchings :data-table="dataTable" :loading="loadingTable" />
    </div>
  </div>
</template>

<script>
import DoughnutChart from "@/components/TheDoughnutChart.vue";
import RadarChart from "@/components/TheRadarChart.vue";

// components
import Matchings from "./components/Matchings";

// mixins
import ranking from "@/mixins/ranking";

// service
import { BattleService } from "@/modules/battleCS2/services/battleServices";

export default {
  name: "StatisticView",
  mixins: [ranking],

  components: {
    DoughnutChart,
    RadarChart,
    Matchings,
  },

  props: {
    userInfo: {
      type: Object,
      default: null,
    },
    loadingInfo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      gameCode: "cs2",
      timeOutOnScroll: null,
      loadingTable: false,
      isLoadMore: true,
      dataTable: [],
      pageSize: 20,
      pageIndex: 1,
      colorRadarChart: [
        "#ED1C24",
        "#E69B10",
        "#63C281",
        "#FF507A",
        "#EBE336",
        "#7B72C6",
        "#318AEF",
      ],
      statisticItems: [
        {
          name: "K/D Ratio",
          key: "kd_ratio",
        },
        {
          name: "K/R Ratio",
          key: "kr_ratio",
        },
        {
          name: "HS%",
          key: "hs_ratio",
        },
        {
          name: "HS",
          key: "headshot",
        },
        {
          name: "Kills",
          key: "kills",
        },
        {
          name: "Dead",
          key: "deaths",
        },
      ],
    };
  },

  mounted() {
    this.getHistory();
  },

  methods: {
    async getHistory(loadMore) {
      try {
        this.isLoadMore = false;
        if (!loadMore) this.loadingTable = true;

        const params = {
          userId: this.$route.params.userId,
          page_size: this.pageSize,
          page_index: this.pageIndex,
        };
        const res = await BattleService.getUserHistory(this.gameCode, params);
        this.isLoadMore = Boolean(res.Data && res.Data.length);

        if (!this.isLoadMore) {
          return;
        }

        if (loadMore) {
          this.dataTable = this.dataTable.concat(res.Data ?? []);
          return;
        }

        this.dataTable = res.Data ?? [];
      } catch (error) {
        console.log(error);
        this.isLoadMore = false;
      } finally {
        this.loadingTable = false;
      }
    },

    onScroll() {
      this.timeOutOnScroll && clearTimeout(this.timeOutOnScroll);
      this.timeOutOnScroll = setTimeout(() => {
        var item = document.getElementById("id-statistic");
        if (
          Math.abs(item.scrollHeight - item.clientHeight - item.scrollTop) <
            1 &&
          this.isLoadMore &&
          this.dataTable.length
        ) {
          this.pageIndex += 1;
          this.getHistory(true);
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-statistic {
  height: calc(100vh - 335px);
  padding: 32px 80px 20px 80px;
  overflow: auto;
  .cl-rank-season {
    width: 360px;
    min-width: 360px;
    height: 120px;
    overflow: hidden;
    border-radius: 10px;
  }
  .cl-statistic-item {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 10px;
  }
  .cl-achievement,
  .cl-radar-chart,
  .cl-win-rate {
    width: 360px;
    min-width: 360px;
    height: 180px;
    padding: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #7b72c614;
  }
  .cl-radar-chart {
    width: 400px;
    min-width: 400px;
  }
  .cl-achievement {
    width: 400px;
    min-width: 400px;
    justify-content: center;
  }
}
</style>
