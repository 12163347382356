<template>
  <div class="text-white">
    <div class="p-5 pb-0 flex justify-between">
      <div class="flex">
        <v-btn
          v-for="(item, index) in lstGame"
          :key="index"
          :class="
            item.state == constants.GAME_STATE.Active
              ? 'cl-active'
              : 'cl-unActive'
          "
          class="cl-font-14-bold"
          @click="onChangeTab(index)"
        >
          <div class="flex align-center w-40">
            <img :src="item.iconGame" alt="" srcset="" />
            <div class="flex-1 flex justify-center">{{ item.name }}</div>
          </div>
        </v-btn>
      </div>
      <div class="flex">
        <v-btn
          height="34"
          prepend-icon="mdi-reload"
          variant="tonal"
          @click="onRefreshData"
        >
          <span class="normal-case cl-font-14-normal">Làm mới</span>
        </v-btn>
        <!-- <div
          class="flex items-center w-52 h-9.5 ml-2 border-2 border-white rounded-md pr-2"
        >
          <v-text-field
            v-model="searchKey"
            class="flex-1 h-9.5 mt-n1 ml-n2"
            flat
            dirty
            hide-details
            density="compact"
            variant="solo"
            bg-color="transparent"
            placeholder="Tìm server"
            @change="getServerComunity"
          >
          </v-text-field>
          <v-icon color="#ffff">mdi-magnify</v-icon>
        </div> -->
      </div>
    </div>
    <div class="p-5 pb-0">
      <v-data-table
        id="id-virtual-server"
        :headers="headers"
        :items="lstServer"
        :loading="loading"
        :height="getHeightTable()"
        no-filter
        disable-sort
        fixed-header
        hide-default-footer
        item-value="id"
        items-per-page="-1"
        loading-text="Đang tải dữ liệu"
        no-data-text="Không có dữ liệu"
      >
        <template v-slot:[`item.id`]="{ index }">
          <div class="text-[#979797]">#{{ index + 1 }}</div>
        </template>
        <template v-slot:[`item.players`]="{ item }">
          <div>{{ item.players }}/{{ item.max_players }}</div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            v-show="item.host"
            height="33"
            width="100"
            variant="outlined"
            class="cl-btn-connect"
            @click="onJoinGame(item)"
          >
            <v-icon size="25">mdi-play-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-divider :thickness="1"></v-divider>
    <div class="flex justify-between items-center">
      <img
        class="mt-n16"
        src="@/assets/images/battle-lobby/mod_skins.svg"
        alt=""
        srcset=""
      />
      <v-btn width="211" height="50" variant="outlined" @click="openModeSkins">
        <img
          class="mr-2"
          src="@/assets/images/icons/mod_skins.svg"
          alt=""
          srcset=""
        />
        <span class="cl-font-18-normal">Mod Skins</span>
      </v-btn>
      <div class="pr-5">
        <div class="test-white cl-font-14-bold maxline1">
          Sau khi chọn skin, bạn hãy vào server và thực hiện các bước sau
        </div>
        <div class="mt-2 test-white cl-font-14-normal flex">
          <div
            class="mr-2 bg-white text-black rounded-full w-5 h-5 text-center"
          >
            1
          </div>
          <div class="maxline1">
            Bật <span class="text-customYellow2">bảng chat ingame</span> (phím
            tắt Y/U)
          </div>
        </div>
        <div class="test-white cl-font-14-normal flex mt-1">
          <div
            class="mr-2 bg-white text-black rounded-full w-5 h-5 text-center"
          >
            2
          </div>
          <div class="maxline1">
            Nhập lệnh <span class="text-customYellow2">!WP</span> để refresh
            skin đã chọn
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogPassword
    :showDialog="showPassword"
    @acceptDialog="onAcceptDialog"
    @closeDialog="showPassword = false"
  />
</template>

<script>
import constants from "@/constants";

// service
import Apis from "@/modules/battleCS2/services/apis";
import { BattleService } from "@/modules/battleCS2/services/battleServices";

// mixins
import steamConnect from "@/mixins/steamConnect";

// dialog
import DialogPassword from "@/components/core/dialog/Password";

export default {
  mixins: [steamConnect],

  components: {
    DialogPassword,
  },

  data() {
    return {
      constants,
      showPassword: false,
      infoServer: null,
      lstServer: [],
      loading: false,
      tabActive: null,
      pageIndex: 1,
      pageSize: 200,
      searchKey: null,
      lstGame: [
        {
          name: "CS2",
          gameCode: "cs2",
          gameType: constants.GAME_TYPE.normal,
          iconGame: require("@/assets/images/icons/cs2.svg"),
          state: constants.GAME_STATE.Active,
        },
        {
          name: "CS:GO",
          gameCode: "csgo",
          gameType: constants.GAME_TYPE.normal,
          iconGame: require("@/assets/images/icons/csgo.svg"),
          state: constants.GAME_STATE.UnActive,
        },
        {
          name: "Tournament",
          gameCode: "cs2",
          gameType: constants.GAME_TYPE.tournamentsCommunity,
          iconGame: require("@/assets/images/icons/tournament.svg"),
          state: constants.GAME_STATE.UnActive,
        },
      ],
      headers: [
        {
          title: "#",
          align: "start",
          sortable: false,
          key: "id",
        },
        {
          title: "Server",
          key: "name",
          sortable: false,
          align: "start",
        },
        { title: "IP", key: "host", sortable: false, align: "start" },
        { title: "Port", key: "port", sortable: false, align: "start" },
        { title: "Player", key: "players", sortable: false, align: "start" },
        { title: "Map", key: "map", sortable: false, align: "start" },
        {
          title: "Connect",
          key: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },

  created() {
    this.tabActive = this.lstGame[0];
    this.getServerComunity();
  },

  methods: {
    onJoinGame(item) {
      if (item.password) {
        this.showPassword = true;
        this.infoServer = item;
        return;
      }
      this.joinGameSteam(item.host, item.port, item.password);
    },
    onAcceptDialog(password) {
      this.showPassword = false;
      this.joinGameSteam(this.infoServer.host, this.infoServer.port, password);
    },
    openModeSkins() {
      const linkModeSkins = "https://wavypzuto.xyz/";
      if (process.env.VUE_APP_BUILD == "DESKTOP-APP") {
        // eslint-disable-next-line
        const { shell } = require("electron");
        shell.openExternal(linkModeSkins);
        return;
      }
      window.open(linkModeSkins, "_blank");
    },
    getHeightTable() {
      return process.env.VUE_APP_BUILD == "DESKTOP-APP"
        ? "calc(100vh - 255px)"
        : "calc(100vh - 235px)";
    },
    onRefreshData() {
      this.getServerComunity();
    },
    onChangeTab(index) {
      this.tabActive.state = constants.GAME_STATE.UnActive;
      this.tabActive = this.lstGame[index];
      this.tabActive.state = constants.GAME_STATE.Active;
      this.getServerComunity();
    },
    async getServerComunity() {
      this.loading = true;
      try {
        const params = {
          name: this.searchKey,
          game_code: this.tabActive.gameCode,
          type: this.tabActive.gameType,
          index: this.pageIndex,
          size: this.pageSize,
        };
        const res = await BattleService.getListServerCommunity(params);
        if (!res.Data.length) {
          this.loading = false;
        }
        this.lstServer = [];
        res.Data.forEach((it) => {
          this.getSteamGameServer(it);
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async getSteamGameServer(server) {
      const url = `${Apis.GET_STEAM_GAME_SERVER}?key=8356942B53F59221CA7E1DB40C99E539&filter=\\appid\\730\\addr\\${server.host}`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data.response.servers && data.response.servers.length) {
            data.response.servers = data.response.servers.sort(
              (a, b) => a.gameport - b.gameport
            );
            data.response.servers.forEach((item) => {
              this.lstServer.push({
                id: server.id,
                name: item.name,
                host: server.host,
                port: item.gameport,
                password: server.password,
                map: item.map,
                players: item.players,
                max_players: item.max_players,
              });
            });
          }
          this.loading = false;
        })
        .catch((error) => console.error("Error:", error));
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-active,
.cl-unActive {
  width: 163px;
  height: 34px;
  color: #fff;
  margin-right: 10px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, 0.1);
}
.cl-active {
  color: rgba(230, 186, 32, 1);
  border: 1px solid rgba(230, 186, 32, 1);
  background: rgba(201, 157, 0, 0.3);
}
::v-deep(.v-text-field input) {
  color: rgba(255, 255, 255, 1) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
#id-virtual-server {
  overflow-y: auto !important;
}
.cl-btn-connect {
  color: #505050;
  background: transparent;
  &:hover {
    color: white;
    background: #389d50;
  }
}
.v-table {
  color: #fff !important;
  background: transparent !important;
  ::v-deep(hr) {
    display: none;
  }
}
::v-deep(.v-table__wrapper) {
  overflow: initial !important;
}
::v-deep(.v-data-table__td:first-child) {
  -moz-border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
}
::v-deep(.v-data-table__td:last-child) {
  -moz-border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
}
::v-deep(.v-data-table__td) {
  border: 0px !important;
}
::v-deep(.v-data-table__tr) {
  height: 60px !important;
  background: #7b72c614;
}
::v-deep(.v-data-table__th) {
  color: rgba(151, 151, 151, 1);
  height: 60px !important;
  background: rgba(0, 0, 0) !important;
}
::v-deep(.v-data-table-header__content) {
  font-size: 14px;
  font-weight: 700;
}
::v-deep(.v-data-table__td) {
  font-size: 16px;
  font-weight: 700;
}
::v-deep(.v-data-table__tr:hover) {
  background: rgba(123, 114, 198, 0.2);
}
::v-deep(table) {
  border-collapse: separate !important;
  border-spacing: 0 5px !important;
}
</style>