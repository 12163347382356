import routesName from "./routesName";
import routesPath from "./routesPath";

// component
import DetailEvent from "@/modules/missions/view/DetailEvent.vue";
import DetailReward from "@/modules/missions/view/DetailReward.vue";

export const routersMission = [
  {
    name: routesName.EVENT_DETAIL,
    path: routesPath.EVENT_DETAIL_PATH,
    component: DetailEvent,
  },
  {
    name: routesName.REWARD_HISTORY,
    path: routesPath.REWARD_HISTORY_PATH,
    component: DetailReward,
  },
];
