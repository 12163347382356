<template>
  <v-avatar :size="sizeAvatar" :image="data?.avatar_url"></v-avatar>
  <v-avatar
    v-if="data?.frame_url"
    class="absolute"
    :size="sizeFrame"
    :image="data?.frame_url"
  >
  </v-avatar>
  <div
    v-show="data?.banned"
    class="cl-ban cl-font-16-bold"
    :style="{ width: `${sizeFrame + 4}px` }"
  >
    <img src="@/assets/images/icons/ban.svg" alt="" />
  </div>
  <div v-show="data?.banned" class="cl-ban-text cl-font-16-bold">Ban</div>
  <v-tooltip v-if="tooltip" width="300" activator="parent" location="top">
    <v-list-item :prepend-avatar="data?.avatar_url">
      <template v-slot:title>
        <span class="w-full maxline2">{{ data?.name }}</span>
      </template>
      <template v-slot:subtitle>
        <div class="cl-font-14-normal flex">
          <v-icon size="16">mdi-steam</v-icon>
          <span class="w-full maxline2">
            &nbsp;{{ data?.steam_name ?? data?.steam_display_name }}
          </span>
        </div>
      </template>
    </v-list-item>
  </v-tooltip>
</template>

<script>
export default {
  name: "CoreAvatar",

  props: {
    data: {
      typeof: Boolean,
      default: null,
    },
    sizeAvatar: {
      typeof: Number,
      default: 38,
    },
    sizeFrame: {
      typeof: Number,
      default: 44,
    },
    tooltip: {
      typeof: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-overlay__content) {
  background: #502f9f !important;
}
.cl-ban-text,
.cl-ban {
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 50%;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
}
.cl-ban-text {
  color: #e44e4e;
  background: transparent;
  text-align: center;
  align-content: center;
  -webkit-text-stroke: 1px #000000;
  paint-order: stroke fill;
}
</style>