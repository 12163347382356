<template>
  <div class="cl-elo-ban">
    <div v-if="item?.elo_boost_image" class="cl-elo">
      <img :src="item?.elo_boost_image" alt="" />
    </div>
    <div
      v-if="item?.penalty_period_time > 0"
      class="cl-ban pt-1 cl-font-14-normal"
    >
      <v-icon size="13" color="#D81F26">mdi-block-helper</v-icon>&nbsp;
      <span>{{ formattedTime }}</span>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { formattedTimeCountDown } from "@/helpers/utils";

export default {
  emit: ["closeBanTime"],

  props: {
    item: {
      type: Object,
      default: null,
    },
    timeCountDown: {
      type: Number,
      default: 0,
    },
  },

  setup(props, { emit }) {
    const intervalId = ref(null);
    const banTime = ref(props.timeCountDown);

    const formattedTime = computed(() => {
      return formattedTimeCountDown(JSON.parse(JSON.stringify(banTime.value)));
    });

    watch(
      () => props.timeCountDown,
      () => {
        onCountDown();
      }
    );

    const onCountDown = () => {
      if (banTime.value > 0) {
        if (intervalId.value) {
          clearInterval(intervalId.value);
        }
        banTime.value = props.timeCountDown;
        intervalId.value = setInterval(() => {
          if (banTime.value > 0) {
            banTime.value -= 1;
            return;
          }
          clearInterval(intervalId.value);
          emit("closeBanTime");
        }, 1000);
      }
    };

    onMounted(() => {
      if (props.timeCountDown > 0) {
        onCountDown();
      }
    });

    return {
      banTime,
      formattedTime,
    };
  },
};
</script>

<style lang="scss" scoped>
.cl-elo-ban {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 200px;
  width: 65px;

  .cl-elo {
    width: 100%;
    margin-bottom: 8px;
  }

  .cl-ban {
    width: 100%;
    display: flex;
    height: 20px;
    padding: 2px;
    border-radius: 10px;
    color: #ff6b6a;
    border: 1px solid #ff6b6a;
    background: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
  }
}
</style>
