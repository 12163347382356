<template>
  <v-dialog v-model="dialogItems" max-width="800" persistent>
    <div class="cl-dialog noselect">
      <img
        class="cl-bg"
        src="@/assets/images/dialog/bg-reward-items.svg"
        alt=""
      />
      <div class="flex w-full h-full justify-between">
        <div class="w-80 mr-2 flex flex-col justify-between items-center">
          <div class="flex flex-1 flex-col justify-center">
            <img
              class="object-cover"
              :src="info.ImageUrl ?? info.image ?? info.image_url"
              alt=""
            />
          </div>
          <div class="text-center">
            <div class="uppercase text-white cl-font-18-bold">
              {{ info.ItemTitle ?? info.name }}
            </div>
            <div
              v-if="info.ItemGPoint"
              class="uppercase text-customYellow2 cl-font-18-bold"
            >
              {{ formatterNumber(info.ItemGPoint) }} {{ unit }}
            </div>
          </div>
        </div>

        <div class="flex-1 flex flex-col items-center">
          <div class="cl-title">
            {{ info.ItemTitle ?? "thông tin nhận thưởng" }}
          </div>
          <div class="text-white cl-font-16-bold mt-2">
            Vui lòng liên hệ fanpage
            <a
              href="https://m.me/104589905650276"
              class="text-customYellow5"
              target="_blank"
            >
              GPLAY CS2
            </a>
            để xác thực thông tin. Phần quà sẽ được trao cho người chơi từ 7-10
            ngày kể từ thời điểm admin xác nhận thành công.
          </div>
          <v-form
            ref="form"
            class="w-full h-full flex flex-col space-between mt-9"
            :class="
              info.ItemSubcategoryId == constants.SUB_CATEGORY.box
                ? 'space-between'
                : 'justify-end'
            "
          >
            <div
              v-show="info.ItemSubcategoryId == constants.SUB_CATEGORY.box"
              class="flex-1 text-white cl-font-12-normal"
            >
              Trade offer link sẽ cho phép bạn gửi yêu cầu trao đổi các vật phẩm
              trong game đến người chơi khác một cách nhanh chóng và dễ dàng
            </div>
            <div class="mb-2 mt-2">
              <div class="text-white cl-font-14-bold mt-1">Số điện thoại</div>
              <v-text-field
                v-model="phoneModel"
                class="custom-placeholer"
                flat
                dirty
                autofocus
                type="email"
                color="#7B72C6"
                density="compact"
                variant="outlined"
                base-color="#7B72C6"
                bg-color="rgba(123, 114, 198, 0.3)"
                hide-details="auto"
                :maxLength="10"
                :rules="[rules.regexPhone]"
                :placeholder="'vd: 035xxxxxxxx'"
              >
              </v-text-field>
            </div>
            <div>
              <div class="text-white cl-font-14-bold">
                {{
                  info.ItemSubcategoryId == constants.SUB_CATEGORY.box
                    ? "Nhập Link Trade Offer"
                    : "Email"
                }}
              </div>
              <v-text-field
                v-model="emailModel"
                flat
                dirty
                color="#7B72C6"
                density="compact"
                variant="outlined"
                base-color="#7B72C6"
                bg-color="rgba(123, 114, 198, 0.3)"
                hide-details="auto"
                :rules="
                  info.ItemSubcategoryId == constants.SUB_CATEGORY.box
                    ? []
                    : [rules.regexEmail]
                "
                :placeholder="
                  info.ItemSubcategoryId == constants.SUB_CATEGORY.box
                    ? 'vd: https://steamcommunity.com/my/tradeoffers'
                    : 'vd: nguyenvana@gtv.vn'
                "
              >
              </v-text-field>
            </div>
          </v-form>

          <div class="mt-8 w-full flex justify-between">
            <v-btn class="cl-btn-accept" @click="onAccept">
              <span class="cl-font-24-bold"> xác nhận </span>
            </v-btn>
            <v-btn class="cl-btn-cancel" @click="onCloseDialog">
              <v-icon size="35">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <v-btn
        width="32"
        height="32"
        class="absolute right-2 top-2"
        icon
        variant="plain"
        color="#7B72C6"
        @click="onCloseDialog"
      >
        <v-icon size="32">mdi-close</v-icon>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import constants from "@/constants";

export default {
  name: "DialogItems",
  emit: ["closeDialog", "acceptDialog"],
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    info: {
      type: Object,
      default: undefined,
    },
    unit: {
      typeof: String,
      default: "Gạo",
    },
    keyMoney: {
      typeof: String,
      default: "ItemCoin",
    },
    keySaleMoney: {
      typeof: String,
      default: "ItemSaleCoin",
    },
  },

  data() {
    return {
      constants,
      dialogItems: this.value,
      emailModel: "",
      phoneModel: "",
      regexPhone: /^[0-9.,()+]+$/,
      regexEmail:
        "^[A-Za-z0-9-\\+\\_]+(\\.[A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$",
      rules: {
        regexPhone: (v) => {
          const regex = this.regexPhone;
          if (v && !regex.test(v)) {
            return "Số điện thoại không hợp lệ";
          }
          return true;
        },
        regexEmail: (v) => {
          var regex = new RegExp(this.regexEmail);
          if (v && !regex.exec(v)) {
            return "Email không đúng định dạng";
          }
          return true;
        },
      },
    };
  },

  watch: {
    dialogItems(val, oldVal) {
      if (val === oldVal) return;
    },

    value(val, oldVal) {
      if (val === oldVal) return;
      this.dialogItems = val;
    },
  },

  methods: {
    onAccept() {
      this.$refs.form.validate().then((value) => {
        if (value.valid) {
          this.$emit("acceptDialog", {
            EmailLink: this.emailModel,
            PhoneNumber: this.phoneModel,
          });
        }
      });
    },

    onCloseDialog() {
      this.$emit("closeDialog");
    },

    formatterNumber(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "decimal",
      });
      return formatter.format(amount).replaceAll(",", ".");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-text-field input) {
  color: #ffffff !important;
  border-radius: 3px !important;
}

::v-deep(.v-text-field input::placeholder) {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  text-align: left;
}

.cl-dialog {
  width: 800px;
  min-height: 450px;
  height: max-content;
  padding: 20px 40px;
  .cl-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .cl-btn-accept {
    width: 250px;
    height: 60px;
  }
  .cl-btn-cancel {
    width: 60px;
    height: 60px;
    color: #7b72c6;
    border: 1px solid #7b72c6;
    background: transparent;
  }
}
</style>
