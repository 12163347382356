<template>
  <v-dialog v-model="internalValue" max-width="450" min-width="450" persistent>
    <div class="cl-dialog">
      <img
        class="cl-bg"
        src="@/assets/images/dialog/bg_count_down.svg"
        alt=""
      />
      <div class="cl-title mt-3 mb-3">Tố cáo</div>
      <div class="cl-member-report cl-font-14-bold">
        Người chơi tố cáo
        <v-list-item
          class="cl-item"
          :title="player?.name"
          :prepend-avatar="player?.avatar_url"
        ></v-list-item>
      </div>
      <div class="cl-reson-report cl-font-16-normal">
        Lý do tố cáo
        <v-checkbox
          v-for="(item, index) in lstResonReport"
          :key="index"
          v-model="selected"
          class="cl-checkbox"
          base-color="#613BBB"
          color="#613BBB"
          :label="item.label"
          :value="item.value"
          hide-details
          true-icon="mdi-checkbox-outline"
        >
        </v-checkbox>
      </div>
      <v-btn class="cl-btn-accept" @click.stop="onAccept"> Tố cáo </v-btn>
      <v-btn class="cl-btn-close" icon @click.stop="onCloseDialog">
        <v-icon size="34">mdi-window-close</v-icon>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogReport",
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    player: {
      type: Object,
      required: false,
      default: null,
    },
    onCloseDialog: {
      type: Function,
      default: undefined,
    },
    onAccept: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      internalValue: this.value,
      selected: null,
      lstResonReport: [
        {
          label: "Hack",
          value: "Hack",
        },
        {
          label: "Spam",
          value: "Spam",
        },
        {
          label: "Sử dụng ngôn từ thiếu văn hóa",
          value: "Sử dụng ngôn từ thiếu văn hóa",
        },
        {
          label: "Lý do khác",
          value: "Lý do khác",
        },
      ],
    };
  },
  watch: {
    internalValue(val, oldVal) {
      if (val === oldVal) return;
    },
    value(val, oldVal) {
      if (val === oldVal) return;
      this.internalValue = val;
    },
  },
  methods: {
    getResonReport() {
      return this.selected;
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-dialog {
  width: 450px;
  height: max-content;
  justify-content: space-around;
  .cl-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .cl-btn-accept {
    width: 180px;
    height: 40px;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .cl-btn-close {
    top: 0px;
    right: 10px;
    position: absolute;
    color: #7b72c6;
    background: transparent;
  }
  .cl-reson-report,
  .cl-member-report {
    width: 300px;
    color: #fff;
    .cl-item {
      display: flex;
      justify-content: center;
    }
  }
  .cl-reson-report {
    margin: 10px 0px 30px 0px;
    .cl-checkbox {
      height: 40px;
      color: #ffffff;
    }
  }
}
</style>