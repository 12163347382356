<template>
  <v-dialog v-model="dialogItems" max-width="800" persistent>
    <div class="cl-dialog noselect">
      <img
        class="cl-bg"
        src="@/assets/images/dialog/bg-reward-items.svg"
        alt=""
      />
      <div class="flex w-full justify-between">
        <div class="w-80 mr-2 flex flex-col justify-between items-center">
          <div class="flex flex-1 flex-col justify-center">
            <img
              class="object-cover"
              :src="info.ImageUrl ?? info.image ?? info.image_url"
              alt=""
            />
          </div>
          <div class="text-center">
            <div class="uppercase text-white cl-font-18-bold">
              {{ info.ItemTitle ?? info.name }}
            </div>
            <div
              v-if="info.ItemGPoint"
              class="uppercase text-customYellow2 cl-font-18-bold"
            >
              {{ formatterNumber(info.ItemGPoint) }} {{ unit }}
            </div>
          </div>
        </div>

        <div class="flex-1 relative flex flex-col items-center justify-betwwen">
          <div class="cl-title">
            {{ info.ItemTitle ?? "thông tin nhận thưởng" }}
          </div>
          <div class="text-white cl-font-16-bold mt-2">
            Vui lòng liên hệ qua fanpage
            <a
              href="https://m.me/104589905650276"
              class="text-customYellow5"
              target="_blank"
            >
              GPLAY CS2
            </a>
            để xác thực thông tin. Quà sẽ được trao từ 3 đến 7 ngày
          </div>
          <v-form ref="formInfo" class="w-full mt-9">
            <div class="text-white cl-font-14-bold">Tên người nhận</div>
            <v-text-field
              v-model="data.ReceiverName"
              flat
              dirty
              autofocus
              color="#7B72C6"
              density="compact"
              variant="outlined"
              base-color="#7B72C6"
              bg-color="rgba(123, 114, 198, 0.3)"
              hide-details
            >
            </v-text-field>
            <div class="text-white mt-4 cl-font-14-bold">Địa chỉ nhận hàng</div>
            <v-text-field
              v-model="data.ReceiverAddress"
              flat
              dirty
              color="#7B72C6"
              density="compact"
              variant="outlined"
              base-color="#7B72C6"
              bg-color="rgba(123, 114, 198, 0.3)"
              hide-details
            >
            </v-text-field>
            <div class="text-white mt-4 cl-font-14-bold">Số điện thoại</div>
            <v-text-field
              v-model="data.ReceiverPhoneNumber"
              flat
              dirty
              type="email"
              maxLength="10"
              color="#7B72C6"
              density="compact"
              variant="outlined"
              base-color="#7B72C6"
              bg-color="rgba(123, 114, 198, 0.3)"
              :rules="[rules.regexPhone]"
              hide-spin-buttons
            >
            </v-text-field>
          </v-form>
          <v-btn class="mt-8 cl-btn-accept" @click="onAccept">
            <span class="cl-font-24-bold"> gửi </span>
          </v-btn>
        </div>
      </div>
      <v-btn
        width="32"
        height="32"
        class="absolute right-2 top-2"
        icon
        variant="plain"
        color="#7B72C6"
        @click="$emit('closeDialog')"
      >
        <v-icon size="32">mdi-close</v-icon>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogItems",
  emit: ["closeDialog"],
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    info: {
      type: Object,
      default: undefined,
    },
    unit: {
      typeof: String,
      default: "Gạo",
    },
    keyMoney: {
      typeof: String,
      default: "ItemCoin",
    },
    keySaleMoney: {
      typeof: String,
      default: "ItemSaleCoin",
    },
  },

  data() {
    return {
      dialogItems: this.value,
      data: {
        ReceiverName: "",
        ReceiverAddress: "",
        ReceiverPhoneNumber: "",
      },
      regexPhone: /^[0-9.,()+]+$/,
      rules: {
        regexPhone: (v) => {
          const regex = this.regexPhone;
          if (v && !regex.test(v.trim())) {
            return "Số điện thoại không hợp lệ";
          }
          return true;
        },
      },
    };
  },

  watch: {
    dialogItems(val, oldVal) {
      if (val === oldVal) return;
    },

    value(val, oldVal) {
      if (val === oldVal) return;
      this.dialogItems = val;
    },
  },

  methods: {
    async onAccept() {
      if (
        !this.data.ReceiverName.length &&
        !this.data.ReceiverAddress.length &&
        !this.data.ReceiverPhoneNumber.length
      ) {
        this.$emit("closeDialog");
        return;
      }
      const { valid } = await this.$refs.formInfo.validate();
      if (valid) {
        this.$emit("acceptDialog", this.data);
      }
    },

    formatterNumber(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "decimal",
      });
      return formatter.format(amount).replaceAll(",", ".");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-text-field input) {
  color: #ffffff !important;
  border-radius: 3px !important;
}

.cl-dialog {
  width: 800px;
  min-height: 450px;
  height: max-content;
  padding: 10px 40px;
  .cl-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .cl-btn-accept {
    width: 250px;
    height: 60px;
  }
}
</style>
