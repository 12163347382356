<template>
  <div class="cl-battle-lobby noselect">
    <div class="cl-lobby-action">
      <div class="cl-left">
        <v-btn
          class="cl-btn-competition"
          width="160"
          variant="outlined"
          color="rgba(255, 80, 122, 1)"
          height="34"
        >
          {{ isEndseries ? $t("lobby.result") : $t("lobby.match") }}
        </v-btn>
      </div>
      <!---->
      <v-btn
        v-if="isEndseries"
        class="cl-btn-back"
        width="180"
        variant="outlined"
        height="34"
        @click="onBackFindMatch"
      >
        <v-icon class="mr-3">mdi-arrow-left</v-icon>
        {{ $t("lobby.back_to_find_mach") }}
      </v-btn>
    </div>
    <TeamInfo
      ref="teamInfo"
      :is-endseries="isEndseries"
      :lobby-info="lobbyInfo"
      :lobby-id="lobbyId"
      :current-member="currentMember"
      :current-match="currentMatch"
      @joinGame="
        joinGameSteam(
          lobbyInfo.server.host,
          lobbyInfo.server.port,
          lobbyInfo.server.password
        )
      "
    />
    <!-- MAP INFO -->
    <MapInfo
      :is-endseries="isEndseries"
      :current-match="currentMatch"
      :lobby-info="lobbyInfo"
      :current-member="currentMember"
    />
    <div v-if="lobbyId" class="cl-btn-message">
      <BtnMessage :id="lobbyId" />
    </div>
    <div
      v-show="!isEndseries && lobbyInfo?.state == constants.LOBBY_STATE.running"
      class="cl-btn-console"
    >
      <div class="cl-font-12-bold text-white">
        <div>Kết nối</div>
        <div>Game console</div>
      </div>
      <div class="cl-console">
        <v-btn
          class="cl-font-16-bold mormal-case"
          height="24"
          variant="text"
          color="#8E8E93"
          :style="{ 'text-transform': 'none' }"
          @click.stop="isShowConsole = !isShowConsole"
        >
          {{ isShowConsole ? "Ẩn" : "Hiện" }}
        </v-btn>
        <div
          class="text-white whitespace-nowrap absolute w-36 left-16 maxline1"
        >
          {{ isShowConsole ? getCopyCmd() : "" }}
        </div>
        <v-btn
          class="mr-1 ml-1"
          height="24"
          variant="tonal"
          color="#fff"
          @click="onCopyCmd"
        >
          COPY
        </v-btn>
      </div>
    </div>
    <DrawerChat :id="lobbyId" :title="'drawer_chat.room_chat'" />
  </div>
</template>

<script>
import constants from "@/constants";

// component
import BtnMessage from "@/components/core/BtnMessage.vue";
import TeamInfo from "@/modules/battleCS2/view/components/LobbyTeamInfo.vue";
import MapInfo from "@/modules/battleCS2/view/components/LobbyMapInfo.vue";
import DrawerChat from "@/components/TheDrawerChat.vue";

// mixins
import mixinSteam from "@/mixins/steamConnect";

// router
import routesName from "@/modules/battleCS2/routers/routesName";

// service
import { BattleService } from "@/modules/battleCS2/services/battleServices";

// storage
import { getUserInfo, clearStorageWhenLogout } from "@/helpers/storage";

// utils
import { handleFocusApp } from "@/helpers/utils";

export default {
  name: "LobbyBattle",
  mixins: [mixinSteam],

  components: {
    BtnMessage,
    TeamInfo,
    MapInfo,
    DrawerChat,
  },

  data() {
    return {
      constants,
      sound: null,
      idSound: null,
      isEndseries: false,
      isShowConsole: false,
      lobbyId: null,
      lobbyInfo: null,
      currentMatch: null,
      currentMember: null,
      gameCode: "cs2",
      actionMqtt: [
        {
          action: "cancel_lobby",
          handleAction: () => this.handleCancelLobby(),
        },
        {
          action: "lobby_match",
          handleAction: (val) => this.handleLobbyMatch(val),
        },
        {
          action: "update_map_match",
          handleAction: (val) => this.handleUpdateMapMatch(val),
        },
        {
          action: "user_state",
          handleAction: (val) => this.handleUserState(val),
        },
        {
          action: "lobby_state",
          handleAction: (val) => this.handleLobbyState(val),
        },
        {
          action: "end_match_ranking",
          handleAction: (val) => this.handleEndMatch(val),
        },
        {
          action: "user_banned",
          handleAction: (val) => this.handleUserBanner(val),
        },
      ],
      lobbyMatchState: [
        {
          state: "create_match_success",
          handleState: () => this.handleCreateMatchSuccess(),
        },
        {
          state: "create_match_failed",
          handleState: (val) => this.handleCancelLobby(val),
        },
        {
          state: "timeout_match",
          handleState: (val) => this.handleCancelLobby(val),
        },
        {
          state: "reject_match",
          handleState: (val) => this.handleCancelLobby(val),
        },
        {
          state: "end_round",
          handleState: (val) => this.handleEndRound(val),
        },
        {
          state: "end_series",
          handleState: () => this.handleEndSeries(),
        },
        {
          state: "end_match",
          handleState: () => this.handleEndSeries(),
        },
      ],
    };
  },

  watch: {
    "$route.params.lobbyId"() {
      this.$router.go();
    },
  },

  beforeMount() {
    this.onUnSubscribeMqttLobby();
  },

  mounted() {
    if (this.$route.query.gameId) {
      this.handleEndSeries();
      this.getHistoryMatch();
    } else {
      if (process.env.VUE_APP_BUILD == "DESKTOP-APP") {
        this.lobbyId = this.$route.params.lobbyId;
        this.getLobbyInfo();
        this.getCurrentMatch();
        this.onSubscribeMqttLobby();
        this.onListenMessageMqtt();
      } else {
        this.$router.push({
          path: constants.ROOT_PATH,
        });
      }
    }
    handleFocusApp();
  },

  methods: {
    async getHistoryMatch() {
      try {
        const res = await BattleService.getHistoryMatch(
          this.$route.query.gameId
        );
        const userInfo = JSON.parse(getUserInfo());
        res.Data?.red_team_stats.forEach((it) => {
          it.team_index = 1;
          if (userInfo.user_id === it.player.user_uuid) this.currentMember = it;
        });
        res.Data?.blue_team_stats.forEach((it) => {
          it.team_index = 2;
          if (userInfo.user_id === it.player.user_uuid) this.currentMember = it;
        });
        this.lobbyInfo = {
          red_team_name: res.Data.red_team_name,
          red_team_logo: res.Data.red_team_logo,
          blue_team_name: res.Data.blue_team_name,
          blue_team_logo: res.Data.blue_team_logo,
        };
        setTimeout(() => {
          this.handleEndMatch(res.Data);
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },

    async getLobbyInfo() {
      try {
        const res = await BattleService.getLobbyInfo(
          this.gameCode,
          this.lobbyId
        );
        this.lobbyInfo = res.Data;
        const userInfo = JSON.parse(getUserInfo());
        this.lobbyInfo?.red_team_member.forEach((it) => {
          it.team_index = 1;
          if (userInfo.user_id === it.user_uuid) this.currentMember = it;
        });
        this.lobbyInfo?.blue_team_member.forEach((it) => {
          it.team_index = 2;
          if (userInfo.user_id === it.user_uuid) this.currentMember = it;
        });
      } catch (error) {
        this.onBackFindMatch();
      }
    },

    async getCurrentMatch() {
      try {
        const res = await BattleService.getCurrentMatch(
          this.gameCode,
          this.lobbyId
        );
        this.currentMatch = res.Data.match_current;
      } catch (error) {
        console.log(error);
      }
    },

    getCopyCmd() {
      const { host, port } = this.getServerGameInfo();
      return `connect ${host}:${port}`;
    },

    getServerGameInfo() {
      const host = this.lobbyInfo.server.host;
      const port = this.lobbyInfo.server.port;
      const password = this.lobbyInfo.server.password;
      return {
        host: host,
        port: port,
        password: password,
      };
    },

    async onCopyCmd() {
      try {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          await navigator.clipboard.writeText(this.getCopyCmd());
        }
      } catch (error) {
        console.log(error);
      }
    },

    async handleCreateMatchSuccess() {
      try {
        BattleService.startGame();
        const { host, port, password } = this.getServerGameInfo();
        this.joinGameSteam(host, port, password);
        handleFocusApp();
      } catch (error) {
        console.log(error);
      }
    },

    handleCancelLobby() {
      if (!this.isEndseries) {
        this.onBackFindMatch();
      }
    },

    handleEndRound(data) {
      this.currentMatch = data?.data?.match_current;
    },

    async handleEndMatch(data) {
      const { red_team_stats, blue_team_stats } = data;
      this.currentMatch = data;
      if (this.$refs.teamInfo) {
        this.$refs.teamInfo.setResultTeamA(
          await this.handleResultTeam(
            this.currentMember?.team_index === 1
              ? red_team_stats
              : blue_team_stats
          )
        );
        this.$refs.teamInfo.setResultTeamB(
          await this.handleResultTeam(
            this.currentMember?.team_index === 1
              ? blue_team_stats
              : red_team_stats
          )
        );
        this.$refs.teamInfo.setLoadingResult(false);
      }
      this.isEndseries = true;
    },

    async handleUserBanner(data) {
      try {
        if (data?.red_team_member && data?.red_team_member.length) {
          this.findUserBan("red_team_member", data);
        }
        if (data?.blue_team_member && data?.blue_team_member.length) {
          this.findUserBan("blue_team_member", data);
        }
      } catch (error) {
        console.log(error);
      }
    },

    findUserBan(key, data) {
      const userInfo = JSON.parse(getUserInfo());
      data[key].forEach((it) => {
        if (it.user_uuid === userInfo.user_id && it.banned) {
          clearStorageWhenLogout();
          this.$router.push(constants.LOGIN_PATH);
          return;
        }
        const index = this.lobbyInfo[key].findIndex(
          (item) => item.user_uuid === it.user_uuid
        );
        if (index > -1) {
          this.lobbyInfo[key][index].banned = it.banned;
        }
      });
    },

    async handleResultTeam(data) {
      let team = [];
      try {
        data.forEach((it) => {
          team.push({
            userId: it.player.user_uuid,
            name: it.player.display_name,
            steam_name: it.player.steam_meta.display_name_steam,
            steam_id: it.player.steam_meta.steam_id,
            avatar_url: it.player.avatar_url,
            frame_url: it.player.frame_url,
            kill: it.statistic.kills,
            die: it.statistic.deaths,
            assit: it.statistic.assists,
            headshot: it.statistic.headshot_kills,
            damage: it.statistic.damage,
            mvp: it.statistic.mvp,
            banned: it.player.banned,
            elo:
              it.player.elo_change < 0
                ? it.player.elo_change
                : `+${it.player.elo_change}`,
          });
        });
      } catch (error) {
        console.log(error);
        team = [];
      }
      return JSON.parse(
        JSON.stringify(team.sort((a, b) => b.damage - a.damage))
      );
    },

    handleEndSeries() {
      this.isEndseries = true;
      if (this.$refs.teamInfo) {
        this.$refs.teamInfo.setLoadingResult(true);
      }
    },

    handleLobbyMatch(data) {
      const state = data.match_state;
      const match = this.lobbyMatchState.find((it) => it.state === state);
      if (match) {
        match.handleState(data);
      }
    },

    handleUpdateMapMatch(data) {
      this.lobbyInfo.is_ban_pick_map = data?.is_ban_pick_map;
      this.lobbyInfo.ban_pick_map_start_time = data?.ban_pick_map_start_time;
      this.lobbyInfo.ban_pick_map_team_idx = data?.ban_pick_map_team_idx;
      if (data.map_list && data.map_list.length) {
        const mapIndex = this.lobbyInfo.map_list.findIndex(
          (it) => it.id === data.map_list[0].id
        );
        if (mapIndex >= 0) {
          this.lobbyInfo.map_list[mapIndex] = data.map_list[0];
        }
      }
    },

    handleUserState(data) {
      const { red_team_member, blue_team_member } = data;
      if (this.currentMember?.team_index === 1) {
        this.handleCurrentMember(red_team_member);
        return;
      }
      this.handleCurrentMember(blue_team_member);
    },

    handleCurrentMember(arrayMember) {
      const indexTeam = this.currentMember?.team_index;
      if (arrayMember && arrayMember.length) {
        const member = arrayMember.find(
          (it) => it.user_uuid === this.currentMember.user_uuid
        );
        if (member) {
          member.team_index = indexTeam;
          this.currentMember = member;
        }
      }
    },

    handleLobbyState(data) {
      this.lobbyInfo.state = data.state;
    },

    onBackFindMatch() {
      this.$router.push({
        name: routesName.ROOM_BATTLE,
      });
    },

    // MQTT
    onUnSubscribeMqttLobby() {
      if (!this.lobbyId) {
        return;
      }

      const topicLobby = `${process.env.VUE_APP_TOPIC_LOBBY}/${this.gameCode}/${this.lobbyId}`;
      const topicLobbyRaning = `${process.env.VUE_APP_TOPIC_LOBBY_RANKING}/${this.gameCode}/${this.lobbyId}`;

      this.$clientMqtt.unsubscribe(topicLobby);
      this.$clientMqtt.unsubscribe(topicLobbyRaning);
    },

    onSubscribeMqttLobby() {
      const topicLobby = `${process.env.VUE_APP_TOPIC_LOBBY}/${this.gameCode}/${this.lobbyId}`;
      const topicLobbyRaning = `${process.env.VUE_APP_TOPIC_LOBBY_RANKING}/${this.gameCode}/${this.lobbyId}`;

      this.$clientMqtt.subscribe(topicLobby);

      this.$clientMqtt.subscribe(topicLobbyRaning);
    },

    onListenMessageMqtt() {
      const topicLobby = `${process.env.VUE_APP_TOPIC_LOBBY}/${this.gameCode}/${this.lobbyId}`;
      const topicLobbyRaning = `${process.env.VUE_APP_TOPIC_LOBBY_RANKING}/${this.gameCode}/${this.lobbyId}`;

      this.$clientMqtt.on("message", async (topic, message) => {
        if (topic != topicLobby && topic != topicLobbyRaning) {
          return;
        }
        const decoder = new TextDecoder("utf-8");
        const { Action, Data } = JSON.parse(decoder.decode(message));
        console.log(Action);
        console.log(Data);
        const action = this.actionMqtt.find((it) => it.action === Action);
        if (action) {
          action.handleAction(Data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-battle-lobby {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px 8px 10px 20px;
  justify-content: space-between;
  flex-direction: column;

  .cl-lobby-action {
    width: 100%;
    display: flex;
    height: max-content;
    justify-content: space-between;
    .cl-left {
      display: flex;
      .cl-result {
        width: 160px;
        height: 34px;
        border-radius: 6px;
        text-align: center;
        align-content: center;
        color: rgba(255, 255, 255, 1);
        background: rgba(255, 255, 255, 0.1);
      }
    }
    .cl-btn-back,
    .cl-btn-competition {
      text-transform: none;
    }
    .cl-btn-back {
      color: #ffffff;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  .cl-btn-message {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .cl-btn-console {
    position: absolute;
    left: 23px;
    bottom: 10px;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    .cl-console {
      width: 320px;
      height: 34px;
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 3px;
      justify-content: space-between;
      border: 1px solid rgba(255, 255, 255, 1);
      overflow: hidden;
    }
  }
}
</style>