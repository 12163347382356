<template>
  <RadarChart v-bind="radarChartProps" />
</template>

<script>
import { Chart, registerables } from "chart.js";
import { RadarChart, useRadarChart } from "vue-chart-3";
import { computed } from "vue";

Chart.register(...registerables);

export default {
  name: "TheRadarChart",
  components: {
    RadarChart,
  },
  props: {
    labels: {
      type: Array,
      default: () => ["Aim", "Snipe", "Assist", "Clutch", "Entry", "MVP"],
    },
    data: {
      type: Array,
      default: () => [40, 3, 23, 11, 49, 49],
    },
  },
  setup(props) {
    const chartData = computed(() => ({
      labels: props.labels,
      datasets: [
        {
          fill: true,
          data: props.data,
          backgroundColor: "rgba(250, 173, 31, 0.4)",
          borderColor: "rgba(250, 173, 31, 1)",
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
        },
      ],
    }));
    const { radarChartProps, radarChartRef } = useRadarChart({
      chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            borderWidth: 1,
          },
        },
        scales: {
          r: {
            startAngle: 0,
            suggestedMin: 0,
            suggestedMax: 100,
            pointRotation: 30,
            pointLabels: {
              font: {
                size: 12,
                weight: 700,
              },
              color: "rgba(255, 255, 255, 1)",
              display: true,
            },
            ticks: {
              stepSize: 20,
              display: false,
            },
            grid: {
              lineWidth: 2,
              color: "rgba(80, 47, 159, 1)",
            },
            angleLines: {
              display: false,
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
      },
    });
    return { radarChartProps, radarChartRef };
  },
};
</script>