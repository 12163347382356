import routePath from '@/routers/routesPath'

export default {
  ROOM_BATTLE_PATH: `${routePath.G_BATTLE_CS2_PATH}/room`,
  LOBBY_BATTLE_PATH: `${routePath.G_BATTLE_CS2_PATH}/lobby/:lobbyId`,
  FIND_TEAM_PATH: `${routePath.G_BATTLE_CS2_PATH}/find/team`,
  RANKING: `${routePath.G_BATTLE_CS2_PATH}/ranking`,
  NORMAL: `${routePath.G_BATTLE_CS2_PATH}/normal`,
  RANKING_NORMAL_PATH: `${routePath.G_BATTLE_CS2_PATH}/ranking/normal`,
  HISTORY_BATTLE_PATH: `${routePath.G_BATTLE_CS2_PATH}/history`
}